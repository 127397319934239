'use client';

import { Button, CardHeader, CircularProgress, Flex, Heading, Text } from '@chakra-ui/react';
import { CfCard, CfTooltip, IconCaretRight, IconCheck } from '@cryptofi/core-ui';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import { KycAlert, OnboardingStep } from '~/components';
import { onboardingSteps, systemOnboardingUnavailableMessage } from '~/constants';
import { useGlobalModalContext, useOnboardingInfo, usePostTelemetryEvent, useSystemDownByFeature } from '~/hooks';

const CreateAccountCard = () => {
  const {
    isOnboarded,
    isRejectedOrPending,
    isRejectedOrPendingSecurities,
    isRejectedOrPendingCrypto,
    onboardingStatusCrypto,
    onboardingStatusSecurities,
    currentStep,
    totalSteps,
  } = useOnboardingInfo();
  const { onOpenModal } = useGlobalModalContext();

  const { trackEvent } = usePostTelemetryEvent();
  const { isSystemDownByFeature } = useSystemDownByFeature();

  /* eslint-disable no-restricted-syntax */
  const completedSteps = isRejectedOrPending || isOnboarded ? totalSteps : currentStep - 1;
  const progress = Math.round((completedSteps / totalSteps) * 100);
  /* eslint-enable no-restricted-syntax */

  const handleCreateAccountClick = () => {
    // Fire telemetry event
    trackEvent(TelemetryClientSideEventsEnum.ClickedPortfolioCompleteProfileButtonClient);
    // Open onboarding modal
    onOpenModal();
  };

  return (
    <CfCard gap={6}>
      {!isRejectedOrPending && !isOnboarded && (
        <CardHeader p="0" display="flex" alignItems="center" gap={4}>
          {progress === 100 ? (
            <IconCheck h="6" w="6" __css={{ path: { fill: 'green.500' } }} />
          ) : (
            <CircularProgress value={progress || 10} color="brand.500" />
          )}

          <Heading as="h1" size="md" gap={4}>
            {`Create account (${completedSteps}/${totalSteps})`}
          </Heading>
        </CardHeader>
      )}

      {isRejectedOrPending && (
        <Flex direction="column" gap={4}>
          {isRejectedOrPendingSecurities && (
            <KycAlert product="securities" onboardingStatus={onboardingStatusSecurities} />
          )}

          {isRejectedOrPendingCrypto && <KycAlert product="crypto" onboardingStatus={onboardingStatusCrypto} />}
        </Flex>
      )}

      {!isRejectedOrPending && (
        <>
          <Flex gap={4} direction={{ base: 'column', md: 'row' }} justifyContent="center">
            {onboardingSteps.slice(0, totalSteps).map((step) => (
              <OnboardingStep key={step.title} stepData={step} showDescription />
            ))}
          </Flex>

          <Flex direction="column" align="flex-end">
            <CfTooltip
              label={
                isSystemDownByFeature() ? (
                  <Text textAlign="center" maxWidth="12rem">
                    {systemOnboardingUnavailableMessage}
                  </Text>
                ) : null
              }
            >
              <Button
                isDisabled={isSystemDownByFeature()}
                onClick={handleCreateAccountClick}
                variant="secondary"
                rightIcon={<IconCaretRight />}
                w={{ base: 'full', md: 'auto' }}
              >
                Create account
              </Button>
            </CfTooltip>
          </Flex>
        </>
      )}
    </CfCard>
  );
};

export default CreateAccountCard;
