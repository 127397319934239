'use client';

import { Link } from '@chakra-ui/next-js';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  ResponsiveValue,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import { dateFormats, formatUsd, IconCaretDown, uiColors } from '@cryptofi/core-ui';
import dayjs from 'dayjs';
import { Camelized } from 'humps';

import { Transaction } from '~/codegen/types';
import { AssetLogo, LabelTd, TransactionStatus, ValueTd } from '~/components';
import { FrontEndOrderStatus } from '~/constants';
import { uiRoutes } from '~/routes';
import { isCrypto } from '~/utils';

type TransactionAccordionProps = {
  transaction: Camelized<Transaction>;
  initiallyCollapsed?: boolean;
  variant?: ResponsiveValue<string> | undefined;
  allowToggle?: boolean;
};

const TransactionAccordion = ({
  transaction,
  initiallyCollapsed = false,
  variant,
  allowToggle = true,
}: TransactionAccordionProps) => {
  const isPending = FrontEndOrderStatus[transaction.status as keyof typeof FrontEndOrderStatus] === 'Pending';
  const isCrypto = transaction.assetType === 'CRYPTO';

  const hasValidAmount = transaction.assetAmount && transaction.assetAmount > 0;
  const decimalPlaces = isCrypto ? 8 : 5;
  const formattedAmount = hasValidAmount ? transaction.assetAmount?.toFixed(decimalPlaces) : 0;

  return (
    <Accordion
      w="full"
      defaultIndex={initiallyCollapsed ? undefined : [0]}
      allowToggle={allowToggle}
      variant={variant || ''}
    >
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              cursor={allowToggle ? 'pointer' : 'auto'}
              _hover={allowToggle ? { bg: uiColors.lighthouse() } : { bg: uiColors.white() }}
            >
              <Flex w="full" justifyContent="space-between" alignItems="center">
                <Link
                  href={
                    isCrypto
                      ? uiRoutes.assetDetailsCrypto(transaction.asset!)
                      : uiRoutes.assetDetailsSecurities(transaction.asset!)
                  }
                >
                  <AssetLogo assetId={transaction?.asset || ''} assetType={isCrypto ? 'crypto' : 'securities'} showId />
                </Link>

                <Flex direction="column">
                  <Text fontFamily="heading" textAlign="right">
                    {formattedAmount}
                  </Text>

                  <Text textAlign="right">
                    {dayjs.utc(transaction?.transactTime).local().format(dateFormats.dateOnly)}
                  </Text>
                </Flex>
              </Flex>

              {allowToggle && (
                <IconCaretDown
                  ml="4"
                  transform={isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)'}
                  transition="transform 0.2s ease-in-out"
                  h="1rem"
                  w="1rem"
                  __css={{ path: { fill: uiColors.sonicSilver() } }}
                />
              )}
            </AccordionButton>

            <AccordionPanel background={uiColors.lighthouse()}>
              <Table variant="unstyled">
                <Tbody>
                  <Tr fontFamily="heading" fontSize="md">
                    <Td pl="0" py="2.5">
                      Status
                    </Td>

                    <Td pr="0" py="2.5" textAlign="right">
                      <TransactionStatus transaction={transaction} justifyContent="flex-end" />
                    </Td>
                  </Tr>

                  <Tr>
                    <LabelTd>Type</LabelTd>

                    <ValueTd>{transaction.transactionName}</ValueTd>
                  </Tr>

                  <Tr>
                    <LabelTd>Asset price</LabelTd>

                    <ValueTd>
                      {isPending ? <Skeleton>$0.00</Skeleton> : formatUsd({ amount: transaction.assetPrice || 0 })}
                    </ValueTd>
                  </Tr>

                  <Tr>
                    <LabelTd>Amount</LabelTd>

                    <ValueTd>{formatUsd({ amount: transaction.usdAmountGross || 0 })}</ValueTd>
                  </Tr>

                  <Tr>
                    <LabelTd>Transaction fee</LabelTd>

                    <ValueTd>{formatUsd({ amount: transaction.feeAmount || 0 })}</ValueTd>
                  </Tr>
                </Tbody>
              </Table>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default TransactionAccordion;
